import * as React from 'react'
import Link from '../components/link'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  return (
    <Layout knockoutHeader={false}>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <div className='flex flex-col px-container justify-center text-center py-5'>
        <h1 className='font-light text-xl sm:text-2xl mt-5'>
          Sorry! The page you’re looking for cannot be found.
        </h1>
        <p className='mt-4'>
          <Link
            to='/'
            variant='muted'
            arrowDirection='left'
            arrowPosition='before'
            className='font-semi-bold text-base sm:text-md'
            children='Return to homepage'
          />
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
